import React from 'react'
import { map } from 'lodash'
import { db, useDatabaseValue } from './firebase'
import { api } from './api'

export const App: React.FC = () => {
  const [value] = useDatabaseValue<any>(db.child('demands'))
  const clickButton = React.useCallback(() => {
    api.get('/hello')
  }, [])
  return (
    <div>
      <button onClick={clickButton}>Click me!</button>
      {map(value, (v, id) => (
        <p key={id}>{JSON.stringify(v)}</p>
      ))}
    </div>
  )
}
