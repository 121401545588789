const firebaseAccount = {
  apiKey: 'AIzaSyAwnB2O3KWhUgCMeRq01VCxEt0UdGoExAo',
  authDomain: 'lenarge-demandas-internas.firebaseapp.com',
  databaseURL: 'https://lenarge-demandas-internas.firebaseio.com',
  projectId: 'lenarge-demandas-internas',
  storageBucket: 'lenarge-demandas-internas.appspot.com',
  messagingSenderId: '38286319551',
  appId: '1:38286319551:web:5d77d79aa08cbb13628b0a',
  measurementId: 'G-BZSR1YT2YG',
}

export default firebaseAccount
