import * as firebase from 'firebase/app'
import 'firebase/database'
import { useObjectVal } from 'react-firebase-hooks/database'

import firebaseAccount from './firebase-account'
const firebaseApp = firebase.initializeApp(firebaseAccount)

export default firebaseApp

export const db = firebaseApp.database().ref()

export const useDatabaseValue = useObjectVal

export function dbKey(): string {
  const uniqueKey = db.push().key
  if (!uniqueKey) throw new Error('internal_server_error: Cannot generate server unique key')
  return uniqueKey
}

export async function dbGet<T>(ref: any): Promise<T | null> {
  const snapshot = await ref.once('value')
  if (!snapshot.exists()) return null
  return snapshot.val()
}

export async function dbSet<T>(ref: any, val: T | null): Promise<void> {
  await ref.set(val)
}

export async function dbTxn<T>(ref: any, f: (v: T | null, time: number) => NonNullable<T> | null, debug?: boolean): Promise<T | null> {
  let error: Error | undefined
  let attempt = 0
  const time = Date.now()
  const id = 'tx:' + time
  const { committed, snapshot } = await ref.transaction((v: any) => {
    error = undefined
    attempt++
    try {
      if (debug) console.log(id + ' attempt', attempt)
      if (debug) console.log(id + ' input', JSON.stringify(v))
      const r = f(v, time)
      if (debug) console.log(id + ' output', JSON.stringify(r))
      return r
    } catch (e) {
      if (debug) console.log(id + ' error', e)
      error = e
      if (v === null) return null // special case, firebase specific
      return undefined
    }
  })
  if (debug) console.log(id + ' outcome', JSON.stringify(snapshot ? snapshot.val() : undefined))
  if (error) throw error
  if (!committed || !snapshot) throw new Error('internal_server_error: Transaction not committed')
  return snapshot.val()
}
